import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
import {WEB_CLIENT_HEADER} from '../constants';

export interface PersonDetails {
    avatarURL: string;
    firstName: string;
    fullName: string;
    credit: string;
    currency: string;
    lang: string;
    id: string;
    email: string;
    loginType: string;
    intercomUserHashWeb: string;
    dateJoined: number;
    fbId: string;
}

interface PersonDetailsFromServer {
    avatar_small: string;
    first_name: string;
    full_name: string;
    credit_display: string;
    intercom_user_hash_web: string;
    email: string;
    id: string;
    type: string;
    date_joined: number;
    currency: string;
    lang: string;
    fb_id: string;
}

export interface PersonData {
    isLoggedIn: boolean;
    personDetails?: PersonDetails;
}

export const getUserData = async (): Promise<PersonData> => {
    const cookies = new Cookies();
    const jwt = cookies.get<string>('jwt');

    const result: PersonData = {
        isLoggedIn: false,
    };

    if (jwt) {
        const decoded = jwt_decode(jwt);
        if (
            decoded &&
            decoded['person_id'] &&
            typeof decoded['person_id'] === 'number'
        ) {
            const person_id: number = decoded['person_id'];

            const headers = {
                'Content-type': 'application/json',
                Authorization: `Token ${jwt}`,
                ...WEB_CLIENT_HEADER,
            };

            const url = `${process.env.NEXT_PUBLIC_SERVER_BASE_URL}/api/v1/persons/${person_id}`;

            return fetch(url, {method: 'get', headers: headers}).then(
                (response) => {
                    if (!response.ok) return result;

                    return response
                        .json()
                        .then((data: PersonDetailsFromServer) => {
                            result['isLoggedIn'] = true;
                            result['personDetails'] = {
                                avatarURL: data.avatar_small,
                                firstName: data.first_name,
                                credit: data.credit_display,
                                id: data.id,
                                email: data.email,
                                loginType: data.type,
                                intercomUserHashWeb:
                                    data.intercom_user_hash_web,
                                dateJoined: data.date_joined,
                                fullName: data.full_name,
                                currency: data.currency,
                                lang: data.lang,
                                fbId: data.fb_id,
                            };

                            return result;
                        });
                }
            );
        }
    }

    return Promise.resolve(result);
};
