import React from 'react';
import {styled} from 'styled-components';
import {StyledProps} from '../../modules/common';
import {ItemProps, FoldableItem} from '../foldable-menu/foldable-Item';

export interface SideNavProps extends StyledProps {
    isActive: boolean;
    items: Array<ItemProps>;
}

const UnstyledSideNav = (props: SideNavProps): JSX.Element => {
    const {className, items} = props;
    return (
        <div className={className}>
            <div>
                {items?.map((item, index) => (
                    <FoldableItem key={index} {...item} />
                ))}
            </div>
        </div>
    );
};

export const SideNav = styled(UnstyledSideNav)`
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: ${({theme}) => theme.intercomZIndex + 2};
    top: 0;
    left: 0;
    background-color: ${({theme}) => theme.colors.arroz};
    overflow-x: hidden;
    overflow-y: hidden;
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    margin-top: ${({theme}) => theme.mobileMeasures.homeNavBarHeight}${({theme}) => theme.unitsOfMeasure.px};
    transform: translate(-100%, 0);

    > div {
        overflow-y: auto;
        height: calc(
            100% - ${({theme}) => theme.mobileMeasures.homeNavBarHeight}${({theme}) => theme.unitsOfMeasure.px}
        );
    }

    ${(props): string =>
        props.isActive &&
        `
        transform: translate(0, 0);
    `}
`;
