import React, {useState} from 'react';
import {StyledProps} from '../../modules/common';
import {styled} from 'styled-components';
import {ItemProps, FoldableItem} from './foldable-Item';
import {typographyBodyStyles} from '../../modules/common/styles/typography';
import {shadowBottomShort} from '../shadows.style';

export interface FoldableProps extends StyledProps {
    items: Array<ItemProps>;
}

const FoldableList = styled('ul')`
    ${typographyBodyStyles}
    display: block;
    position: absolute;
    right: ${({theme}): string => theme.spacing.m};
    left: unset;
    border-radius: 0;
    border: none;
    width: 264px;
    ${shadowBottomShort}
    top: 100%;
    z-index: 1000;
    float: left;
    min-width: 160px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: left;
    background-color: ${(props): string => props.theme.colors.arroz};
    background-clip: padding-box;
`;

export const DropDownSelector = styled('img')`
    width: 22px;
    height: 22px;
`;

const UnestyledFoldableMenu = (props: FoldableProps): JSX.Element => {
    const {className, items} = props;
    const [isOpen, setIsOpen] = useState(false);

    const onClickEvent = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={className}>
            <DropDownSelector
                data-testid="foldable-dropdown-action"
                onClick={onClickEvent}
                src="/static/landings/miscellanea/down.svg"
                alt={'left'}
            />
            {isOpen && (
                <FoldableList data-testid="foldable-list">
                    {items.map((item: ItemProps, index: number) => (
                        <FoldableItem {...item} key={index} />
                    ))}
                </FoldableList>
            )}
        </div>
    );
};

export const FoldableMenu = styled(UnestyledFoldableMenu)`
    cursor: pointer;
    display: flex;
`;
