import React from 'react';
import {StyledProps, typographyBodyStyles} from '../../modules/common/styles';
import {styled} from 'styled-components';

interface CommonProps extends StyledProps {
    icon?: string;
    value: string;
    border?: boolean;
}

export type ItemProps = CommonProps &
    (
        | {url?: string; onClickEvent?(): never}
        | {onClickEvent?(): void; url?: never}
    );

const UnestyledFoldableItem = (props: ItemProps): JSX.Element => {
    const {className, value, url, icon, onClickEvent} = props;
    return (
        <a className={className} href={url} onClick={onClickEvent}>
            {/* eslint-disable-next-line @next/next/no-img-element*/}
            <img src={icon} />
            <span>{value}</span>
        </a>
    );
};

export const FoldableItem = styled(UnestyledFoldableItem)`
    ${typographyBodyStyles}
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border-top: ${(props): string =>
        props.border ? `1px solid ${props.theme.colors.caballa}` : 'unset'};
    color: ${({theme}): string => theme.colors.ballena};
    padding: ${({theme}): string => theme.spacing.m};
    text-decoration: none;

    > img {
        margin-right: ${({theme}): string => theme.spacing.m};
    }

    &:hover {
        span {
            color: ${(props): string => props.theme.colors.plancton};
        }
    }
`;
