import {RECIPIENT_URL_PARAM} from '../constants';

/**
 * Given an URL, returns the default value the recharge input component should use.
 *
 * If the url has the query string, recipient, it returns its value. Otherwise, it returns ''
 *
 * @param url
 */
export function getInitialValueForRechargeInputFromURL(url: string): string {
    const urlObj = new URL(url);
    return urlObj.searchParams.get(RECIPIENT_URL_PARAM) ?? '';
}
