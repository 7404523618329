import React from 'react';
import {styled} from 'styled-components';
import {StyledProps, mediaQueries} from '../../modules/common';
import {typographyTitleBodyStyles} from '../../modules/common/styles/typography';
import {FoldableMenu} from '../foldable-menu/foldable-menu';
import {ItemProps} from '../foldable-menu/foldable-Item';
import {I18n} from '../../modules/util/i18n/i18n-interface';
import withTranslation from 'next-translate/withTranslation';
import {WithAnalytics} from '../../lib/analytics/analytics';
import {withAnalytics} from '../../modules/analytics/analytics';
import {
    useMenuItems,
    MenuTypes,
} from '../../modules/util/utilities/get-menu-items';

interface PersonData {
    firstName: string;
    avatarURL: string;
}

export interface PersonWidgetProps extends StyledProps, WithAnalytics {
    personData: PersonData;
    i18n: I18n;
}

const Avatar = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin: 0 ${({theme}) => theme.spacing.xs} 0 0;
`;

const HomeFoldableMenu = styled(FoldableMenu)`
    ${mediaQueries.lessThan('small')`
        display: none;
    `};
`;

const UnstyledPersonWidget = (props: PersonWidgetProps): JSX.Element => {
    const {className, personData, analytics} = props;
    const {i18n} = props;
    const items: ItemProps[] = useMenuItems(
        {i18n, analytics},
        MenuTypes.DropDown
    );

    return (
        <div className={className}>
            <div>
                <Avatar src={personData.avatarURL} />
                <span>{personData.firstName}</span>
            </div>
            <HomeFoldableMenu items={items} />
        </div>
    );
};

/**
 * Component that renders a foldable menu
 */
const BasicPersonWidget = styled(UnstyledPersonWidget)`
    max-width: 185px;
    min-width: 130px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${({theme}) => `calc(${theme.spacing.xs} - 1px )`};
    margin: ${({theme}) => theme.spacing.xs};
    border-radius: 24px;
    border: solid 1px ${(props): string => props.theme.colors.atun};
    background-color: ${(props): string => props.theme.colors.arroz};

    > div:first-of-type {
        display: flex;
        align-items: center;
        overflow: hidden;

        span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin-right: 4px;
        }
    }

    > span {
        ${typographyTitleBodyStyles}
        align-self: center;
    }

    ${mediaQueries.lessThan('small')`
        width: 48px;
        max-width: unset;
        min-width: unset;

        > img {
            margin 0;
        }
        
        > div { 
            > span {
                display: none;
            }
        }
        
    `};
`;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
export const PersonWidget = withAnalytics(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    withTranslation(BasicPersonWidget)
);
