/**
 * Returns the campaign data on a utm
 * @param utms
 */
import {utmPropertyNameMap} from '../constants';

export type UtmMap = Record<string, string>;

export function getCampaignData(utms: UtmMap): Record<string, string> {
    const data = {};
    for (const key in utms) {
        if (utmPropertyNameMap[key] !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            data[utmPropertyNameMap[key]] = utms[key];
        }
    }
    return data;
}
