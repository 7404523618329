import Cookies from 'universal-cookie';
import {Analytics} from '../../../lib/analytics/analytics';
import {EVENTS} from '../constants';

export const logout = (cookies: Cookies, analytics: Analytics): void => {
    const cookieDomain =
        '.' + window.location.hostname.split('.').slice(-2).join('.');

    // expire jwt cookie
    cookies.remove('jwt', {domain: cookieDomain, path: '/'});

    // track logout event
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    void analytics.track(EVENTS.LOGOUT, {}, {});

    window.location.reload();
};
