import React from 'react';
// eslint-disable-next-line import/named
import {NextSeo, NextSeoProps} from 'next-seo';
import {PROJECT_ENVS} from '../../modules/util/constants';

interface HomeLandingSeoProps extends NextSeoProps {
    title: string;
    description: string;
    canonicalUrl: string;
    ogTitle: string;
    ogDescription: string;
}

/**
 * This component contains the SEO configuration for home landing
 */
export const HomeLandingSeo = (props: HomeLandingSeoProps): JSX.Element => {
    const {title, description, canonicalUrl, ogTitle, ogDescription} = props;

    const robotsContent =
        process.env.NEXT_PUBLIC_PROJECT_ENV === PROJECT_ENVS.STAGE
            ? 'noindex, nofollow, noarchive'
            : 'index, follow';
    const baseURL = process.env.NEXT_PUBLIC_ASSETS_URL.replace('/assets', '');

    return (
        <NextSeo
            title={title}
            description={description}
            openGraph={{
                title: ogTitle,
                url: canonicalUrl,
                images: [
                    {
                        url: `${baseURL}/home/v2/facebook-opengraph.png`,
                    },
                ],
                description: ogDescription,
            }}
            additionalMetaTags={[
                {
                    name: 'robots',
                    content: robotsContent,
                },
            ]}
        />
    );
};
