import {ItemProps} from '../../../components/foldable-menu/foldable-Item';
import {I18n} from '../i18n/i18n-interface';
import {changeLanguage} from '../i18n/i18n';
import {WithAnalytics} from '../../../lib/analytics/analytics';
import {logout} from '../../../modules/util/utilities/logout';
import Cookies from 'universal-cookie';
import {useGlobalLoadingIndicator} from '../../../modules/global-indicator-provider/context';

export const MenuTypes = {
    DropDown: 'DropDown',
    Bread: 'Bread',
};

interface MenuFuncParam extends WithAnalytics {
    i18n: I18n;
}

export const useMenuItems = (
    menuFuncParam: MenuFuncParam,
    menuType: string
): ItemProps[] => {
    const {t, lang} = menuFuncParam.i18n;
    const analytics = menuFuncParam.analytics;
    const {showLoadingIndicator} = useGlobalLoadingIndicator();

    const items: ItemProps[] = [
        {
            icon: '/static/landings/miscellanea/recharges.svg',
            value: t('common:recharges'),
            url: '/',
        },
        {
            icon: '/static/landings/miscellanea/history.svg',
            value: t('common:history'),
            url: '/account/recharges',
        },
        {
            icon: '/static/landings/miscellanea/contacts.svg',
            value: t('common:contacts'),
            url: '/account/contacts',
        },
        {
            icon: '/static/landings/miscellanea/account.svg',
            value: t('common:account'),
            url: '/account',
            border: true,
        },
        {
            icon: '/static/landings/miscellanea/invite.svg',
            value: t('common:invite_your_friends'),
            url: '/invitation',
        },
        {
            icon: '/static/landings/miscellanea/promo_discount.svg',
            value: t('common:promos_discounts'),
            url: '/account/promos_discounts',
        },
        {
            icon: '/static/landings/miscellanea/credits.svg',
            value: t('common:credit'),
            url: '/account/credits',
        },
        {
            icon: '/static/landings/miscellanea/switch-language.svg',
            value: t('common:switch_lang'),
            onClickEvent: () => handleClickChangeLanguage(),
            border: true,
        },
        {
            icon: '/static/landings/miscellanea/logout.svg',
            value: t('common:logout'),
            onClickEvent: () => logout(new Cookies(document.cookie), analytics),
        },
    ];

    const handleClickChangeLanguage = () => {
        showLoadingIndicator();
        void changeLanguage(analytics, lang);
    };

    if (menuType === MenuTypes.Bread) {
        return items;
    }

    return items.slice(3);
};
