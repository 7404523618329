import React from 'react';
import {styled} from 'styled-components';
import {BreadMenu} from '../bread-menu/bread-menu';
import {Logo} from '../logo';
import {PersonWidget} from './person-widget';
import {CreditWidget} from './credit-widget';
import {RechargeSection} from './recharge-section';
import {StyledProps, mediaQueries} from '../../modules/common';
import {I18n} from '../../modules/util/i18n/i18n-interface';
import {WithAnalytics} from '../../lib/analytics/analytics';
import {withAnalytics} from '../../modules/analytics/analytics';
import {ItemProps} from '../foldable-menu/foldable-Item';
import withTranslation from 'next-translate/withTranslation';
import {
    useMenuItems,
    MenuTypes,
} from '../../modules/util/utilities/get-menu-items';
import {PersonDetails} from '../../modules/util/utilities/get-user-data';
import {shadowBottomMedium} from '../shadows.style';

const NavBarContentContainer = styled.div`
    margin: 0 auto;
    border: none;
    padding: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const FlexDiv = styled.div`
    display: flex;
`;

const StyledLogo = styled(Logo)`
    margin: 0;
    padding: ${({theme}): string => theme.logo.paddingLogged};

    ${mediaQueries.lessThan('small')`
        padding: 8px;
    `}
`;

export interface BaseNavBarProps extends StyledProps, WithAnalytics {
    longShadow: boolean;
    personDetails: PersonDetails;
    i18n: I18n;
}

/**
 * Base component the Logged-in navbar
 */
const BaseNavBar = (props: BaseNavBarProps): JSX.Element => {
    const {className, personDetails, analytics} = props;
    const {i18n} = props;

    const items: ItemProps[] = useMenuItems({i18n, analytics}, MenuTypes.Bread);

    return (
        <div className={className}>
            <NavBarContentContainer>
                <FlexDiv>
                    <BreadMenu showOnDesktop={false} items={items} />
                    <StyledLogo target="_self" />
                </FlexDiv>
                <RechargeSection />
                <FlexDiv>
                    <CreditWidget credit={personDetails.credit} />
                    <PersonWidget
                        personData={{
                            firstName: personDetails.firstName,
                            avatarURL: personDetails.avatarURL,
                        }}
                    />
                </FlexDiv>
            </NavBarContentContainer>
        </div>
    );
};

/**
 * Logged-in navbar component
 */
const BasicLoggedInNavBar = styled(BaseNavBar)`
    background-color: ${(props): string =>
        props.theme.homeNavBar.backgroundColor};

    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1030;
    min-height: ${(props): string =>
        `${props.theme.desktopMeasures.navBarHeight}${props.theme.unitsOfMeasure.px}`};

    /*Last 2 digits of 8 digits HEX color are for transparency*/
    ${shadowBottomMedium}

    ${mediaQueries.lessThan('small')`
        min-height: ${(props): string =>
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
            `${props.theme.mobileMeasures.navBarHeight}${props.theme.unitsOfMeasure.px}`};
	`};
`;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
export const LoggedInNavBar = withAnalytics(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    withTranslation(BasicLoggedInNavBar)
);
