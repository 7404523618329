import {Dispatch, SetStateAction, useCallback, useState} from 'react';
import {ValidateFn} from './validators';

// note that it matches the error props expected by the text field
type ErrorState = {
    readonly error: boolean;
    readonly errorText?: string;
};
type Validation = [
    ValidateFn,
    ErrorState,
    Dispatch<SetStateAction<ErrorState>>,
];

/**
 * Utility hook to setup validation for input fields
 *
 * The validators will be called sequentially; the order matters.
 *
 * Note: Validators are used as dependencies in useCallback, so either define them outside the component
 * or memoize them
 *
 * For usage, see tests
 * @param validators Validator functions
 * @returns
 */
export function useValidation(...validators: ValidateFn[]): Validation {
    const [error, setError] = useState<ErrorState>({error: false});
    const validate: ValidateFn = useCallback(
        (value: string) => {
            for (const validateFn of validators) {
                const error = validateFn(value);
                if (error) {
                    setError({error: true, errorText: error});
                    return error;
                }
            }
            setError({error: false, errorText: ''});
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [...validators]
    );

    return [validate, error, setError];
}
