// eslint-disable-next-line import/no-duplicates
import {styled, withTheme} from 'styled-components';
// eslint-disable-next-line import/named, import/no-duplicates
import {DefaultTheme} from 'styled-components';

import React from 'react';
import {mediaQueries, StyledProps, ThemedProps} from '../../modules/common';
import withTranslation from 'next-translate/withTranslation';
import {I18n} from '../../modules/util/i18n/i18n-interface';
import {withAnalytics} from '../../modules/analytics/analytics';
import {WithAnalytics} from '../../lib/analytics/analytics';
import {LottieAnimation} from '../lottie-animation';
import Trans from 'next-translate/Trans';
import {
    typographyBodyStyles,
    typographyDisplay3Styles,
    typographyHeading2Styles,
    typographyHeading4Styles,
    typographyLargeBodyStyles,
    typographyTitleBodyStyles,
} from '../../modules/common/styles';

export interface ZigZagItemProps extends StyledProps, WithAnalytics {
    theme?: DefaultTheme;
    i18n: I18n;
    animationLast?: boolean;
    item: ZigZagItemClass;
}

export class ZigZagItemClass {
    animationPath: string;
    animationPlaceholder: React.ReactNode;
    description: React.ReactElement<typeof Trans>;
    title: string;
    link: string;
    linkText: React.ReactNode;

    constructor(
        animationPath: string,
        animationPlaceholder: React.ReactNode,
        description: React.ReactElement<typeof Trans>,
        title: string,
        link: string,
        linkText: React.ReactNode
    ) {
        this.animationPath = animationPath;
        this.animationPlaceholder = animationPlaceholder;
        this.title = title;
        this.description = description;
        this.link = link;
        this.linkText = linkText;
    }
}

const StyledContainer = styled.div`
    width: 50%;

    ${mediaQueries.lessThan('small')`         
        padding-left: unset;
        width: unset;
    `}
`;

const Title = styled.div`
    ${typographyDisplay3Styles}
    margin-bottom: 24px;

    ${mediaQueries.lessThan('small')`         
        ${typographyHeading2Styles}
        text-align: center;
    `}
`;

const Description = styled.div`
    ${typographyLargeBodyStyles}

    ${mediaQueries.lessThan('small')`    
        ${typographyBodyStyles}
        text-align: center;
    `}
`;

const Link = styled.a`
    ${typographyHeading4Styles}
    letter-spacing: normal;
    color: ${({theme}) => theme.colors.plancton};

    display: inline-block;
    margin-top: ${({theme}): string => theme.spacing.xl};

    text-decoration: none;

    ${mediaQueries.lessThan('small')`  
        display: flex;
        justify-content: center;
          
        ${typographyTitleBodyStyles}
        color: ${({theme}: ThemedProps) => theme.colors.plancton};
        margin-top: ${({theme}: ThemedProps): string => theme.spacing.l};
    `}
`;

const Animation = styled(LottieAnimation)`
    width: 50%;
    max-width: 564px;
    height: 290px;

    ${mediaQueries.lessThan('small')`         
        width: unset;
    `}
`;

const ItemPlaceHolder = styled.div`
    width: 50%;
    max-width: 564px;
    height: 290px;

    ${mediaQueries.lessThan('small')`         
        width: 100%;
    `}
`;

const UnstyledZigZagItem = (props: ZigZagItemProps): JSX.Element => {
    const {className, item} = props;
    const itemAnimation = item.animationPath ? (
        <Animation
            path={item.animationPath}
            autoplay={true}
            startWhenVisible={true}
            startOnClick={true}
        >
            {item.animationPlaceholder}
        </Animation>
    ) : (
        <ItemPlaceHolder> {item.animationPlaceholder} </ItemPlaceHolder>
    );

    return (
        <div className={className}>
            {itemAnimation}
            <StyledContainer>
                <Title>{item.title}</Title>
                <Description>{item.description}</Description>
                {item.linkText && <Link href={item.link}>{item.linkText}</Link>}
            </StyledContainer>
        </div>
    );
};

const StyledZigZagItem = styled(UnstyledZigZagItem)`
    display: flex;

    flex-direction: ${({animationLast}) =>
        animationLast ? 'row-reverse' : 'row'};

    justify-content: center;
    align-items: center;

    padding-top: 80px;
    padding-bottom: 80px;

    ${mediaQueries.lessThan('small')`         
        flex-direction: column;
        
        padding-top: unset;
        padding-bottom: ${({theme}: ThemedProps): string =>
            theme.spacing.xxxxl};
    `}
`;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
const ZigZagItem = withAnalytics(withTranslation(withTheme(StyledZigZagItem)));

export {ZigZagItem};
