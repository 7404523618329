import {LOGIN_PATH, TIKTOK_QUERY_NAME, UTM_NAMES} from '../constants';

const SERVER_BASE_URL = process.env.NEXT_PUBLIC_SERVER_BASE_URL;
/**
 * Constructs the url for the login endpoint.
 * Only utm and ttclid params in the query are preserved
 * @param documentUrl The current url ex. document.location.href
 * @returns The absolute url to the login endpoint
 */
export const getLoginUrl = (documentUrl: string): string => {
    const _documentUrl = new URL(documentUrl);
    const documentSearchParams = _documentUrl.searchParams;

    // copy utms to the final url
    const params = new URLSearchParams();
    for (const key of UTM_NAMES) {
        const v = documentSearchParams.get(key);
        if (v) {
            params.append(key, v);
        }
    }

    const ttclid = documentSearchParams.get(TIKTOK_QUERY_NAME);
    if (ttclid) {
        params.append(TIKTOK_QUERY_NAME, ttclid);
    }

    // encode current path + query
    const pathAndQuery = `${_documentUrl.pathname}${_documentUrl.search}`;

    params.append('to', pathAndQuery);
    params.append('from', pathAndQuery);

    // base url + login path + query
    const loginUrl = new URL(LOGIN_PATH, SERVER_BASE_URL);
    loginUrl.search = params.toString();
    return loginUrl.href;
};
