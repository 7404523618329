import React from 'react';
import {NotLoggedInNavBar, NotLoggedInNavBarProps} from './not-logged-in-nav';
import {BaseNavBarProps, LoggedInNavBar} from './logged-in-nav';
import {I18n} from '../../modules/util/i18n/i18n-interface';
// eslint-disable-next-line import/named
import {DefaultTheme} from 'styled-components';

export type NavProps =
    | {
          isLoggedIn: false;
          navBarProps: NotLoggedInNavBarProps & {
              i18n: I18n;
              theme?: DefaultTheme;
          };
      }
    | {
          isLoggedIn: true;
          navBarProps: BaseNavBarProps & {i18n: I18n; theme?: DefaultTheme};
      };

const NavWrapper = (props: NavProps): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {isLoggedIn, navBarProps} = props;

    return (
        <>
            {isLoggedIn ? (
                <LoggedInNavBar {...navBarProps} />
            ) : (
                <NotLoggedInNavBar {...navBarProps} />
            )}
        </>
    );
};

export {NavWrapper};
