import React, {ReactText} from 'react';
import {StyledProps} from '../../modules/common';
import {StyledTextAdornment} from './text-adornment.styles';

type TextAdornmentProps = {
    children?: ReactText;
} & StyledProps;
export const TextAdornment = React.memo(function TextAdornment({
    children,
    className,
}: TextAdornmentProps): JSX.Element {
    return (
        <StyledTextAdornment className={className}>
            {children}
        </StyledTextAdornment>
    );
});
