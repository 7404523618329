export const PROMO_PATH_MAP = {
    Nauta: {
        false: '/promo/recarga_nauta',
        true: '/promo/recarga_nauta_cuba',
    },
    Cubacel: {
        false: '/promo/recarga_cuba',
        true: '/promo/recarga_cubacel_cuba',
    },
    Data_Plans: {
        false: '/promo/planes_datos',
        true: '/promo/planes_datos_cuba',
    },
};
