import React from 'react';
// eslint-disable-next-line import/named
import {NextSeo, NextSeoProps} from 'next-seo';
import {SEOPropsMerger} from '../../modules/util/seo/seo-props-merger';
import useTranslation from 'next-translate/useTranslation';
import {getLandingUrl} from '../../modules/util/utilities/get-landing-url';
import {getLanguageAlternates} from '../../modules/util/seo/get-alternative-langs-href';

export interface FonomaSeoProps extends NextSeoProps {
    operator: string;
    isAcquisition: boolean;
}

/**
 * SEO component that, if not passed in, includes our defaults for title, description, og-title and og-description.
 *
 * NOTE: The translation function is passed to SEOPropsMerger to avoid violating the rule of hooks
 * @param props
 * @constructor
 */
export const FonomaSEO = (props: FonomaSeoProps): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const {t, lang} = useTranslation();
    const {operator, isAcquisition} = props;
    return (
        <NextSeo
            {...SEOPropsMerger(props, t)}
            canonical={getLandingUrl(operator, isAcquisition)}
            languageAlternates={getLanguageAlternates(
                lang,
                operator,
                isAcquisition
            )}
        />
    );
};

export const CanNotRenderSeo = (props: NextSeoProps): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const {t} = useTranslation();

    return <NextSeo {...SEOPropsMerger(props, t)} />;
};
