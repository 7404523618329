import {transparentize} from 'polished';
import React, {useState} from 'react';
import {styled} from 'styled-components';
import {StyledProps, mediaQueries} from '../../modules/common';
import {ItemProps} from '../foldable-menu/foldable-Item';
import {SideNav} from './sidenav';

export interface BreadMenuProps extends StyledProps {
    showOnDesktop: boolean;
    items: ItemProps[];
}

interface BreadActionProps extends StyledProps {
    isActive: boolean;
}

const BasicBreadAction = (props: BreadActionProps): JSX.Element => {
    const {className} = props;
    return (
        <div className={className}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

/**
 * Component that renders a bread menu
 */
export const BreadAction = styled(BasicBreadAction)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: ${({theme}) => theme.spacing.s};
    margin-right: 0;
    padding: ${({theme}) => theme.spacing.xs};
    border-radius: 50%;
    background-color: ${(props): string =>
        transparentize('0.85', props.theme.colors.tiburon)};

    > div {
        width: 18px;
        height: 2px;
        border-radius: 2px;
        background-color: ${(props): string => props.theme.colors.tiburon};
        transition: all 0.4s;
        -webkit-transition: all 0.4s;
        -moz-transition: all 0.4s;
    }

    div:nth-child(2) {
        margin: 3px 0;
    }

    /* 
        Animation behavior
    */
    ${(props): string =>
        props.isActive &&
        `
            div:first-child {
                transform: translateY(5px) translateX(0) rotate(45deg);
                -webkit-transform: translateY(5px) translateX(0) rotate(45deg);
                -moz-transform: translateY(5px) translateX(0) rotate(45deg);
            }
            div:nth-child(2) {
                opacity:0;
            }
            div:nth-child(3) {
                transform: translateY(-7xp) translateX(0) rotate(-45deg);
                -webkit-transform: translateY(-7px) translateX(0) rotate(-45deg);
                -moz-transform: translateY(-7px) translateX(0) rotate(-45deg);
            }
            
    `}
`;

const UnstyledBreadMenu = (props: BreadMenuProps): JSX.Element => {
    const {className, items} = props;
    const [isActive, setIsActive] = useState(false);

    return (
        <div className={className} onClick={() => setIsActive(!isActive)}>
            <BreadAction isActive={isActive} />
            <SideNav isActive={isActive} items={items} />
        </div>
    );
};

export const BreadMenu = styled(UnstyledBreadMenu)`
    display: ${(props): string => (props.showOnDesktop ? 'flex' : 'none')};

    ${mediaQueries.lessThan('small')`
        display: flex;
    `};
`;
