import {
    ChangeEventHandler,
    FocusEventHandler,
    forwardRef,
    MouseEventHandler,
    MutableRefObject,
    ReactElement,
    useEffect,
    useRef,
} from 'react';
import {
    ErrorText,
    InputRoot,
    StyledInput,
    StyledInputProps,
    StyledLabel,
    TextFieldRoot,
} from './text-field.styles';

const EVENTS = [
    'input',
    'keydown',
    'keyup',
    'mousedown',
    'mouseup',
    'select',
    'contextmenu',
    'drop',
];

export function digitsFilterEventListener(
    this: HTMLInputElement & {
        oldValue: string;
        oldSelectionStart: number | null;
        oldSelectionEnd: number | null;
    }
): void {
    if (/^\d*$/.test(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
    } else if (Object.prototype.hasOwnProperty.call(this, 'oldValue')) {
        this.value = this.oldValue;
        if (this.oldSelectionStart !== null && this.oldSelectionEnd !== null) {
            this.setSelectionRange(
                this.oldSelectionStart,
                this.oldSelectionEnd
            );
        }
    } else {
        this.value = '';
    }
}

function setDigitsOnlyFilter(textbox: HTMLInputElement): void {
    EVENTS.forEach(function (event) {
        textbox.addEventListener(event, digitsFilterEventListener);
    });
}

function removeDigitsOnlyFilter(textbox: HTMLInputElement): void {
    EVENTS.forEach(function (event) {
        textbox.removeEventListener(event, digitsFilterEventListener);
    });
}

export type TextFieldProps = {
    /**
     * If `true`, the input element will be disabled.
     */
    disabled?: boolean;

    /**
     * The text to show as a label for the input.
     */
    label?: string;

    /**
     * The input name attr
     */
    name?: string;

    /**
     * Allows full customization of the label element
     */
    labelElement?: ReactElement;

    /**
     * Sets the initial value for the input element
     */
    value?: string | number;

    /**
     * The default input element value. Use when the component is not controlled.
     */
    defaultValue?: string | number;
    /**
     * The text used as a placeholder
     */
    placeholder?: string;
    /**
     * The text to show as error description
     */
    errorText?: string;

    /**
     * Allows full customization of the error element
     */
    errorElement?: ReactElement;

    /**
     * Callback fired when the value is changed.
     */
    onChange?: ChangeEventHandler<HTMLInputElement>;

    /**
     * Callback fired when the input is clicked
     */
    onClick?: MouseEventHandler<HTMLInputElement>;

    /**
     * Callback fired when the input loses focus
     */
    onBlur?: FocusEventHandler<HTMLInputElement>;

    /**
     * Callback fired when the input is focused
     */
    onFocus?: FocusEventHandler<HTMLInputElement>;

    /**
     * The id of the `input` element. Also, it is passed to the `htmlFor` props of the default `label` element.
     */
    id?: string;

    /**
     * Allows further customization of the root (container) element.
     */
    className?: string;

    /**
     * The input type
     */
    inputType?: string;

    /**
     * A component that will be placed at the end (inside) the input
     */
    endAdornment?: ReactElement;

    /**
     * A component that will be placed at the start (inside) the input
     */
    startAdornment?: ReactElement;
} & StyledInputProps;

/**
 * Basic input component
 *
 * The label and error elements can be customized via `labelElement`  and `errorElement` respectively.
 *
 * @example
 * Custom label and error
 *
 * const MyBlueBorderedLabel = <div style={{border: '1px solid blue'}}>{'Look at me!'}</div>
 * const MyRedBorderedError = <div style={{border: '1px solid red'}}>{"You've made a terrible mistake, Karen!"}</div>
 * <TextField
 *  labelElement={<MyBlueBorderedLabel />}
 *  errorElement={<MyRedBorderedError />}
 *  />
 */

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
    function TextField(
        {
            error = false,
            disabled = false,
            label,
            value,
            defaultValue,
            placeholder,
            errorText,
            onChange,
            onClick,
            onBlur,
            onFocus,
            errorElement,
            labelElement,
            id,
            className,
            inputType,
            endAdornment,
            name,
            startAdornment,
        }: TextFieldProps,
        ref: MutableRefObject<HTMLInputElement>
    ): JSX.Element {
        const localRef = useRef<HTMLInputElement>(ref && ref.current);

        useEffect(() => {
            if (!ref) {
                return;
            }
            ref.current = localRef.current;
        }, [ref]);

        const filterEnabledRef = useRef(false);

        // based on https://stackoverflow.com/a/469362
        useEffect(() => {
            const inputElement = localRef.current;

            if (inputElement && inputType === 'number') {
                filterEnabledRef.current = true;
                setDigitsOnlyFilter(inputElement);
            }
            if (inputType !== 'number' && filterEnabledRef.current) {
                removeDigitsOnlyFilter(inputElement);
            }
            return () => {
                if (inputElement && filterEnabledRef.current) {
                    removeDigitsOnlyFilter(inputElement);
                }
            };
        }, [inputType]);

        const labelNode =
            label && label !== '' ? (
                <StyledLabel data-testid="text-field-label" htmlFor={id}>
                    {label}
                </StyledLabel>
            ) : labelElement ? (
                labelElement
            ) : null;
        const errorNode =
            error && errorText ? (
                <ErrorText data-testid="text-field-error">
                    {errorText}
                </ErrorText>
            ) : error && errorElement ? (
                errorElement
            ) : null;
        return (
            <TextFieldRoot
                error={error}
                className={className}
                data-testid="text-field-root"
            >
                {labelNode}
                <InputRoot error={error} disabled={disabled}>
                    {startAdornment}
                    <StyledInput
                        data-testid="text-field-input"
                        id={id}
                        name={name}
                        ref={localRef}
                        error={error}
                        disabled={disabled}
                        value={value}
                        defaultValue={defaultValue}
                        onChange={onChange}
                        onClick={onClick}
                        placeholder={placeholder}
                        type={inputType}
                        onBlur={onBlur}
                        onFocus={onFocus}
                    ></StyledInput>
                    {endAdornment}
                </InputRoot>

                {errorNode}
            </TextFieldRoot>
        );
    }
);
