import {PROMO_PATH_MAP} from './promo-path-map';
import {LANGUAGES} from '../constants';

export interface AlternateLang {
    hrefLang: string;
    href: string;
}

const alternativeLangMap = {
    es: LANGUAGES.ENGLISH,
    en: LANGUAGES.SPANISH,
};

/**
 * Returns the array of AlternateLang for the alternatives URLs
 * @param currentLang
 * @param operator
 * @param isAcquisition
 */
export const getLanguageAlternates = (
    currentLang: string,
    operator: string,
    isAcquisition: boolean
): AlternateLang[] => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const baseUrl = new URL(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument
        PROMO_PATH_MAP[operator][isAcquisition],
        process.env.NEXT_PUBLIC_SERVER_BASE_URL
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const alternativeLanguage = alternativeLangMap[currentLang] as string;

    if (alternativeLanguage !== LANGUAGES.SPANISH) {
        if (baseUrl.host.startsWith('www')) {
            baseUrl.host = baseUrl.host.replace('www', alternativeLanguage);
        } else {
            baseUrl.host = `${alternativeLanguage}.${baseUrl.host}`;
        }
    }

    return [{href: baseUrl.toString(), hrefLang: alternativeLanguage}];
};
