import {styled} from 'styled-components';
import {
    typographyBodyStyles,
    typographySmallBodyStyles,
    typographyLabelStyles,
} from '../../modules/common/styles/typography';
import {shadowBottomShort} from '../shadows.style';

export type StyledInputProps = {
    /**
     * If `true`, the component will be displayed in an error state.
     */
    error?: boolean;
    disabled?: boolean;
};

export const StyledInput = styled.input<StyledInputProps>`
    ${typographyBodyStyles}
    border: none;
    padding: ${({theme}) =>
        `calc(${theme.spacing.s} - 2px )`}; // 2px for the border and padding of InputRoot
    width: 100%;
    background-color: ${({theme, disabled}) =>
        disabled ? theme.colors.caballa : theme.colors.arroz};

    color: ${({theme, disabled}) =>
        disabled ? theme.colors.tiburon : theme.colors.ballena};

    &:focus {
        outline: 0;
    }

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
        color: ${({theme}) => theme.colors.tiburon};
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &[type='number'] {
        -moz-appearance: textfield;
    }

    box-sizing: border-box;
`;

export type StyledLabelProps = StyledInputProps;
export const StyledLabel = styled.label<StyledLabelProps>`
    ${typographyLabelStyles}
    text-align: left;
`;

export const ErrorText = styled.span`
    ${typographySmallBodyStyles}
    text-align: left;
    color: ${({theme}) => theme.colors.error};
`;

export const TextFieldRoot = styled.div<StyledInputProps>`
    display: inline-flex;
    flex-direction: column;
    position: relative;
    vertical-align: top;

    padding-bottom: ${({error, theme}) => (error ? '0px' : theme.spacing.l)};

    ${StyledLabel} {
        margin-bottom: ${({theme}) => theme.spacing.xs};
    }

    ${ErrorText} {
        margin-top: ${({theme}) => theme.spacing.xs};
    }
`;

export const InputRoot = styled.div<StyledInputProps>`
    display: inline-flex;
    position: relative;
    padding: 1px;
    border: 1px solid
        ${({error, theme}) =>
            error ? theme.colors.error : theme.colors.tiburon};
    border-radius: 4px;
    ${shadowBottomShort}
    &,
    & > * {
        background-color: ${({theme, disabled}) =>
            disabled ? theme.colors.caballa : theme.colors.arroz};
    }
`;
