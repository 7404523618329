import {PersonDetails} from '../util/utilities/get-user-data';
import {TIKTOK_QUERY_NAME, UTM_NAMES} from '../util/constants';
import {getCommonAnalyticsData, getTraits} from './utils';
import {parseQuery} from '../util/utilities/parse-query';

export function getHomeLandingIdentifyData(person: PersonDetails) {
    const _documentUrl = new URL(document.location.href);
    const query = _documentUrl.searchParams;
    const utms = {};
    for (const key of UTM_NAMES) {
        const v = query.get(key);
        if (v) {
            utms[key] = v;
        }
    }

    const traits = getTraits(person);

    const options = {
        Intercom: {
            user_hash: person.intercomUserHashWeb,
        },
    };

    const [props, opts] = getCommonAnalyticsData(person, utms, traits, options);

    props[TIKTOK_QUERY_NAME] =
        parseQuery(document.location.search)[TIKTOK_QUERY_NAME] || null;

    return [props, opts];
}
