import React from 'react';
import {styled} from 'styled-components';
import {StyledProps, mediaQueries} from '../../modules/common';
import withTranslation from 'next-translate/withTranslation';
import {I18n} from '../../modules/util/i18n/i18n-interface';
import {typographyTitleBodyStyles} from '../../modules/common/styles';

const NavMenuItem = styled.a`
    ${typographyTitleBodyStyles}
    text-align: center;
    text-decoration: none;
    padding: 24px 0;

    :hover {
        cursor: pointer;
        color: ${(props): string => props.theme.colors.plancton};
    }
`;

const SendRecharge = styled(NavMenuItem)`
    margin-right: ${({theme}) => theme.spacing.xl};
    border-bottom: ${(props): string =>
        `4px solid ${props.theme.colors.plancton}`};
`;

const ContactItem = styled(NavMenuItem)`
    margin-left: ${({theme}) => theme.spacing.xl};
`;

interface Props extends StyledProps {
    i18n: I18n;
}

const UnstyledRechargeSection = (props: Props): JSX.Element => {
    const {className} = props;
    const {t} = props.i18n;
    return (
        <div className={className}>
            <SendRecharge href="/">{t('common:send-recharge')}</SendRecharge>
            <NavMenuItem href="/account/recharges">
                {t('common:recharge-history')}
            </NavMenuItem>
            <ContactItem href="/account/contacts">
                {t('common:contacts')}
            </ContactItem>
        </div>
    );
};

/**
 * Component that renders the nav recharge section
 */
const BasicRechargeSection = styled(UnstyledRechargeSection)`
    display: flex;

    ${mediaQueries.lessThan('medium')`
        display: none;
    `};
`;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
export const RechargeSection = withTranslation(BasicRechargeSection);
