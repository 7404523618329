import isEmpty from 'lodash/isEmpty';
import {parseQuery} from '../util/utilities/parse-query';
import {
    FACEBOOK_FBC_COOKIE_NAME,
    FACEBOOK_FBP_COOKIE_NAME,
    TIKTOK_QUERY_NAME,
} from '../util/constants';
import {getCampaignData, UtmMap} from '../util/utilities/get-campaign-data';
import Cookies from 'universal-cookie';
import {PersonDetails} from '../util/utilities/get-user-data';

/**
 * Given a person, returns its traits.
 * @param person
 */
export function getTraits(person: PersonDetails) {
    return {
        created: new Date(person.dateJoined * 1000),
        email: person.email,
        firstName: person.firstName,
        name: person.fullName,
        loginType: person.loginType,
        lastName: person.fullName.split(' ')[1],
        facebookID: person.fbId || null,
    };
}

export function getCommonAnalyticsData(
    person: PersonDetails,
    utms: UtmMap,
    properties: Record<string, unknown>,
    options = {}
): [Record<string, unknown>, Record<string, unknown>] {
    const cookies = new Cookies(document.cookie);

    const opts = {
        ...options,
        ...{
            context: {
                traits: {
                    ...properties,
                    facebookFbc:
                        cookies.get<string>(FACEBOOK_FBC_COOKIE_NAME) || null,
                    facebookFbp:
                        cookies.get<string>(FACEBOOK_FBP_COOKIE_NAME) || null,
                    ttclid:
                        parseQuery(document.location.search)[
                            TIKTOK_QUERY_NAME
                        ] || null,
                },
            },
        },
    };

    const props = {
        ...properties,
        ...{
            $created: new Date(person.dateJoined * 1000),
            currency: person.currency,
            lang: person.lang,
        },
    };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (!isEmpty(utms)) {
        Object.assign(props, utms);
        Object.assign(opts, {campaign: {...getCampaignData(utms)}});
    }

    if (document.referrer !== '')
        Object.assign(props, {
            $referring_domain: new URL(document.referrer).hostname,
        });

    Object.assign(props, {fnmClient: 'web'});

    return [props, opts];
}
