import React from 'react';
import {withTheme} from 'styled-components';
import {StyledProps, ThemedProps} from '../../modules/common';
import withTranslation from 'next-translate/withTranslation';
import {I18n} from '../../modules/util/i18n/i18n-interface';
import {withAnalytics} from '../../modules/analytics/analytics';
import {WithAnalytics} from '../../lib/analytics/analytics';
import {ZigZagItem, ZigZagItemClass} from './zig-zag-item';
import {Wrapper} from '../home-wrapper';

export interface ZigZagProps extends StyledProps, ThemedProps, WithAnalytics {
    i18n: I18n;
    zigzag?: boolean;
    animationLast?: boolean;
    items: ZigZagItemClass[];
}

const UnstyledZigZag = (props: ZigZagProps): JSX.Element => {
    const {className, items, zigzag, animationLast} = props;
    let animationLastAux = !animationLast;

    return (
        <div className={className}>
            <Wrapper>
                {items.map((item: ZigZagItemClass, i: number) => {
                    if (zigzag) animationLastAux = !animationLastAux;

                    return (
                        <ZigZagItem
                            key={i}
                            item={item}
                            animationLast={animationLastAux}
                        />
                    );
                })}
            </Wrapper>
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
const ZigZag = withAnalytics(withTranslation(withTheme(UnstyledZigZag)));

export {ZigZag};
