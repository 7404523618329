import {LANGUAGES} from './constants';

/**
 * Retrieves the promo URL based on the given language.
 *
 * @param {string} lang - The language code ('es' for Spanish, 'en' for English).
 * @returns {string | undefined} The promo URL for the specified language.
 * @throws {Error} If an unsupported language is provided.
 */
export function getPromoUrl(lang: string): string | undefined {
    switch (lang) {
        case LANGUAGES.SPANISH:
            return process.env.NEXT_PUBLIC_PROMO_URL_ES;
        case LANGUAGES.ENGLISH:
            return process.env.NEXT_PUBLIC_PROMO_URL_EN;
        default:
            throw new Error(`Unsupported language: ${lang}`);
    }
}
