import {PROMO_PATH_MAP} from '../seo/promo-path-map';

/**
 * For an operator and a landing type (acquisition or retention), returns the canonical URL of the landing
 * @param operator
 * @param isAcquisition
 */
export const getLandingUrl = (
    operator: string,
    isAcquisition: boolean
): string => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
    return `${process.env.NEXT_PUBLIC_SERVER_BASE_URL}${PROMO_PATH_MAP[operator][isAcquisition]}`;
};
