import React from 'react';
import {styled} from 'styled-components';
import {StyledProps} from '../../modules/common';
import withTranslation from 'next-translate/withTranslation';
import {I18n} from '../../modules/util/i18n/i18n-interface';
import {
    typographySmallBodyStyles,
    typographyTitleBodyStyles,
} from '../../modules/common/styles/typography';
import {shadowBottomShort} from '../shadows.style';

export interface CreditWidgetProps extends StyledProps {
    credit: string;
    i18n: I18n;
}

const UnstyledCreditWidget = (props: CreditWidgetProps): JSX.Element => {
    const {className, credit} = props;
    const {t} = props.i18n;
    return (
        <a className={className} href="/account/credits">
            {t('common:credit')}
            <span>{credit}</span>
        </a>
    );
};

/**
 * Component that renders a widget with user credit
 */
const BasicCreditWidget = styled(UnstyledCreditWidget)`
    ${typographySmallBodyStyles}
    width: 83px;
    height: 48px;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: ${(props): string => props.theme.colors.ballena};
    align-items: center;
    margin: ${({theme}) => theme.spacing.xs} 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    ${shadowBottomShort}
    background-color: ${(props): string => props.theme.colors.arroz};
    text-decoration: none;

    > span {
        ${typographyTitleBodyStyles}
        height: 20px;
        line-height: 1.25;
        text-align: center;
    }
`;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
export const CreditWidget = withTranslation(BasicCreditWidget);
