import React, {createRef} from 'react';
import lottie, {
    AnimationEventName,
    AnimationItem,
} from 'lottie-web/build/player/lottie_light';
import {StyledProps} from '../modules/common';
import {styled} from 'styled-components';

/**
 * Animation component to render lottie animations
 *
 * Must be passed as props:
 *      path: string; -> Indicates the relative path of the animation to be rendered
 *
 * Can be passed as props:
 *      autoplay?: boolean;         -> Indicates if the animation should start when it is loaded
 *      loop?: boolean;             -> Indicates if the animation should stop after completed
 *      startOnClick?: boolean;     -> To indicate if the animation should start and pause alternatively when clicking on it
 *      startWhenVisible?: boolean; -> To indicate if the animation should start when reaches the viewport when scrolling
 *      children?: React.ReactNode; -> components to be rendered while animation is loading. It is intended to be the first frame of the animation, but could be any component
 *
 * Note: if optional props are not passed they are false/null by default
 *
 * ````example
 * <LottieAnimation
 *      path='/static/landings/animations/new-gift.json'
 *      loop={true}
 *      autoplay={true}
 *      startOnClick={true}
 *      startWhenVisible={true}>
 *          <GiftBoxPlaceholder/>
 * </LottieAnimation>
 * ````
 *
 * ````Styling
 * ````LottieAnimation can be styled using styled-components as follows...
 *
 * const Animation = styled(LottieAnimation)`width:300px`;
 *
 * * <Animation
 *      path='/static/landings/animations/new-gift.json'
 *      autoplay={true}
 *      startWhenVisible={true}>
 *          <GiftBoxPlaceholder/>
 * </Animation>
 *
 * ````
 */

export const AnimationWrapper = styled.div<AnimationWrapperProps>`
    display: ${(props): string => !props.$visible && 'none'};
    height: ${(props): string =>
        props.$wrapperHeight ? `${props.$wrapperHeight}px;` : 'inherit'};
}   
`;

interface AnimationWrapperProps {
    $visible: boolean;
    $wrapperHeight?: number;
}

interface Props extends StyledProps {
    path: string;
    autoplay?: boolean;
    loop?: boolean;
    startOnClick?: boolean;
    startWhenVisible?: boolean;
    actionOnComplete?: () => void;
    actionOnPlay?: () => void;
    canStart?: () => boolean;
    children?: React.ReactNode;
    height?: number;
}

interface State {
    isPlaying: boolean;
    isCompleted: boolean;
    isPaused: boolean;
    isInViewport: boolean;
    loaded: boolean;
    playWhenScrolling: boolean;
    startedOn: number;
}

const startedOn = {
    CLICK: 0,
    SCROLL: 1,
};

interface ExtendedAnimationItem extends AnimationItem {
    onComplete: () => void;
}

class LottieAnimation extends React.Component<Props, State> {
    private animationContainer = createRef<HTMLDivElement>();
    private animationObject: ExtendedAnimationItem = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            isPlaying: false,
            isCompleted: false,
            isPaused: false,
            isInViewport: null,
            loaded: false,
            playWhenScrolling: false,
            startedOn: null,
        };
    }

    componentDidMount(): void {
        const {startWhenVisible} = this.props;

        this.loadAnimation();

        if (startWhenVisible)
            window.addEventListener('scroll', this.onScrollHandler);
    }

    componentWillUnmount = (): void => {
        this.animationObject.removeEventListener('DOMLoaded', this.onLoad);
        this.animationObject.removeEventListener(
            'scroll' as AnimationEventName,
            this.onScrollHandler
        );
    };

    startOnClick = (): void => {
        const {canStart} = this.props;

        if (canStart && !canStart()) return;

        const {isPlaying} = this.state;

        if (isPlaying) {
            this.pause();
            return;
        }

        this.play();

        this.setState({startedOn: startedOn.CLICK});
    };

    loadAnimation = (): void => {
        const {path, loop} = this.props;

        this.animationObject = lottie.loadAnimation({
            container: this.animationContainer.current,
            renderer: 'svg',
            loop: loop ? loop : false,
            autoplay: false,
            path: path,
        }) as ExtendedAnimationItem;

        this.animationObject.addEventListener('DOMLoaded', this.onLoad);
        this.animationObject.onComplete = this.onComplete;
    };

    onComplete = (): void => {
        this.setState({isPlaying: false, isCompleted: true, isPaused: false});

        this.props.actionOnComplete && this.props.actionOnComplete();
    };

    onLoad = (): void => {
        this.setState({loaded: true}, () => {
            const {canStart} = this.props;

            if (canStart && !canStart()) return;

            const isInViewport = this.isInViewport();

            if (isInViewport && this.props.autoplay) this.play();

            this.setState({isInViewport: isInViewport});
        });
    };

    onScrollHandler = (): void => {
        const {canStart} = this.props;
        if (canStart && !canStart()) return;

        const {isInViewport, playWhenScrolling} = this.state;

        if (
            !isInViewport &&
            this.isInViewport() &&
            (this.props.autoplay || playWhenScrolling)
        ) {
            this.setState({isInViewport: true, startedOn: startedOn.SCROLL});
            this.play();
        } else if (isInViewport && !this.isInViewport())
            this.setState({isInViewport: false, startedOn: startedOn.SCROLL});

        if (!playWhenScrolling) this.setState({playWhenScrolling: true});
    };

    play = (): void => {
        const {isPaused} = this.state;
        const {actionOnPlay} = this.props;

        if (!isPaused) this.reset();

        this.animationObject.play();

        actionOnPlay && actionOnPlay();

        this.setState({isPlaying: true, isPaused: false, isCompleted: false});
    };

    reset = (): void => {
        if (this.state.isCompleted) {
            this.animationObject.goToAndStop(1, true);
            this.setState({
                isPlaying: false,
                isCompleted: false,
                isPaused: false,
            });
        }
    };

    pause = (): void => {
        this.setState({isPlaying: false, isPaused: true});

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        this.animationObject.pause();
    };

    isInViewport = (): boolean => {
        if (!this.animationContainer.current) {
            return false;
        }

        const top =
            this.animationContainer.current.getBoundingClientRect()?.top;
        const bottom =
            this.animationContainer.current.getBoundingClientRect()?.bottom;

        const height = window.innerHeight;

        return top >= 0 && bottom <= height && height != 0;
    };

    getBoundingClientRect = (): ClientRect => {
        return this.animationContainer.current.getBoundingClientRect();
    };

    startedOnScroll = (): boolean => {
        return this.state.startedOn == startedOn.SCROLL;
    };

    startedOnClick = (): boolean => {
        return this.state.startedOn == startedOn.CLICK;
    };

    render(): JSX.Element {
        const {className, startOnClick, children, height} = this.props;
        const {loaded} = this.state;

        return (
            <div className={className}>
                {!loaded && children}
                <AnimationWrapper
                    ref={this.animationContainer}
                    $wrapperHeight={height}
                    onClick={startOnClick ? this.startOnClick : null}
                    $visible={loaded}
                    data-testid="lottie-animation-wrapper"
                />
            </div>
        );
    }
}

export {LottieAnimation};
