import React from 'react';
// eslint-disable-next-line import/no-duplicates
import {styled, withTheme} from 'styled-components';
// eslint-disable-next-line import/named, import/no-duplicates
import {DefaultTheme} from 'styled-components';
import {Logo} from '../logo';
import {StyledProps, mediaQueries} from '../../modules/common';
import {ChangeLanguageLinkNavBar} from '../change-language-link';
import {I18n} from '../../modules/util/i18n/i18n-interface';
import withTranslation from 'next-translate/withTranslation';
import {typographyTitleBodyStyles} from '../../modules/common/styles';

interface NavBarProps extends StyledProps, NotLoggedInNavBarProps {
    i18n: I18n;
    theme?: DefaultTheme;
}

export interface NotLoggedInNavBarProps {
    loginUrl: string;
}

const StyledLogo = styled(Logo)`
    padding: ${({theme}): string => theme.logo.padding};

    ${mediaQueries.lessThan('small')`
        padding: 8px 0;
        margin: 0;
    `};
`;

const Button = styled.a`
    padding: ${({theme}): string => theme.spacing.xs}
        ${({theme}): string => theme.spacing.m};
    border-radius: ${({theme}): string => theme.spacing.l};
    border: solid 1px ${(props) => props.theme.colors.tiburon};
    ${typographyTitleBodyStyles}
    background-color: ${(props) => props.theme.colors.arroz};
    text-decoration: none;
`;

const ChangeLanguageMobile = styled(ChangeLanguageLinkNavBar)`
    display: block;
    background-color: unset;
    border: none;
    font-weight: normal;
    padding: 8px 12px;

    ${mediaQueries.greaterThan('small')`
        display: none;
    `};
`;

const ChangeLanguageDesktop = styled(ChangeLanguageLinkNavBar)`
    display: block;
    background-color: unset;
    border: none;
    font-weight: normal;
    padding: 8px 12px;

    ${mediaQueries.lessThan('small')`
        display: none;
    `};

    // To display the lang link when the viewport is exactly in the small breakpoint
    ${mediaQueries.between('small', 'small')`
        display: block;
    `};
`;

const Actions = styled.div`
    display: flex;
    margin: ${({theme}): string => theme.spacing.s} 0;
`;

const BaseNavBar = (props: NavBarProps): JSX.Element => {
    const {className, loginUrl} = props;
    const {t} = props.i18n;

    return (
        <div className={className}>
            <StyledLogo target="_self" />
            <Actions>
                <ChangeLanguageMobile
                    text={t('nav_bar:change-language-mobile')}
                />
                <ChangeLanguageDesktop
                    text={t('nav_bar:change-language-desktop')}
                />
                <Button href={loginUrl}>{t('nav_bar:start-button')}</Button>
            </Actions>
        </div>
    );
};

const StyledNotLoggedInNavBar = styled(BaseNavBar)`
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-right: ${({theme}): string => theme.spacing.l};

    background-color: transparent;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1030;
    min-height: ${({theme}): string =>
        `${theme.desktopMeasures.navBarHeight}${theme.unitsOfMeasure.px}`};

    ${mediaQueries.lessThan('small')`
        min-height: ${(props): string =>
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
            `${props.theme.mobileMeasures.navBarHeight}${props.theme.unitsOfMeasure.px}`};
        padding-right: ${({theme}): string =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-template-expressions
            `${theme.spacing.xs}`};
  `};
`;

// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
const NotLoggedInNavBar = withTranslation(withTheme(StyledNotLoggedInNavBar));

export {NotLoggedInNavBar};
