export type ValidateFn = (value: string | number) => undefined | string;

export class Validators {
    static readonly EMAIL_REGEX =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    static readonly NAUTA_ENDING_REGEX = /.*nauta\.com\.cu|nauta\.co\.cu$/;

    static required(errorMessage: string): ValidateFn {
        return function validateRequired(value: string) {
            let error: string;
            if (value === null || value === undefined || value === '') {
                error = errorMessage;
            }
            return error;
        };
    }

    static phoneNumberLength(
        tooShortMessage: string,
        tooLongMessage: string,
        min = 8,
        max = 8
    ): ValidateFn {
        return function validatePhoneNumberLength(value: string) {
            let error: string;
            if (value && value.length < min) {
                error = tooShortMessage;
            }
            if (value && value.length > max) {
                error = tooLongMessage;
            }
            return error;
        };
    }

    static phoneNumberLengths(
        message: string,
        numberLengths: number[]
    ): ValidateFn {
        return function validatePhoneNumberLengths(value: string) {
            if (numberLengths?.length && value) {
                if (
                    !numberLengths.some(
                        (numberLength) => value.length === numberLength
                    )
                )
                    return message;
            } else return undefined;
        };
    }

    static email(errorMessage: string): ValidateFn {
        const validateEmail = (value: string) => {
            let error: string;
            if (
                value &&
                (!Validators.EMAIL_REGEX.test(value) ||
                    !Validators.NAUTA_ENDING_REGEX.test(value))
            ) {
                error = errorMessage;
            }
            return error;
        };
        return validateEmail;
    }

    static phoneNumberStartsWith(message: string, starts = '5'): ValidateFn {
        return (value: string) => {
            if (value && !value.startsWith(starts)) {
                return message;
            } else return undefined;
        };
    }

    static phoneNumberStartsWithList(
        message: string,
        starts: number[]
    ): ValidateFn {
        return (value: string) => {
            if (starts?.length && value) {
                if (!starts.some((prefix) => value.startsWith(`${prefix}`)))
                    return message;
            } else return undefined;
        };
    }
}
